// ==== axs-table ====

.axs-table.mat-table {
  .mat-cell,
  .mat-footer-cell {
    padding: 0 8px;
    font-size: 13px;
  }

  .mat-header-cell {
    padding: 0 8px;
    font-size: 12px;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 32px;
  }
}

.axs-table.mat-table.dense {
  .mat-cell,
  .mat-header-cell,
  .mat-footer-cell {
    padding: 0 8px;
    font-size: 12px;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 28px;
  }
}
