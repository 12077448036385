// ==== cp-table ====
// Style für mat-table in Listenansichten

.cp-table.mat-table {
  .mat-cell {
    padding: 2px 8px 2px 8px;
  }

  .mat-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.cp-table.mat-table.dense {
  .mat-cell {
    padding: 2px 8px 2px 8px;
  }

  .mat-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 13px;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 32px;
  }
}

// header-row weniger hoch. Unterscheidet sich je nachdem ob
// es eine html table oder eine flex table ist.
table.cp-table.mat-table {
  .mat-header-row {
    height: 46px;
  }

  // Border der letzten Zeile ausblenden
  tbody tr:last-child td {
    border: 0;
  }
}

mat-table.cp-table {
  .mat-header-row {
    min-height: 46px;
  }

  // Border der letzten Zeile ausblenden
  .mat-row:last-child {
    border: 0;
  }
}

// Block für "keine Daten vorhanden" Anzeige
.cp-table-no-data {
  font-size: 14px;
  padding: 8px;
  text-align: center;
}
