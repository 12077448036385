// Kleinere Version von mat-buttons
// class="small" setzen

.mat-button,
.mat-flat-button,
.mat-raised-button {
  &.small {
    padding: 0 8px;
    font-size: 12px;
    line-height: 32px;

    .mat-icon {
      font-size: 22px;
      line-height: 22px;
      width: 22px;
      height: 22px;
    }
  }
}

.mat-icon-button.small {
  width: 36px;
  height: 36px;
  line-height: 32px;

  .mat-icon {
    font-size: 22px;
    line-height: 22px;
    width: 22px;
    height: 22px;
  }
}

.mat-icon-button.xs {
  width: 28px;
  height: 28px;
  line-height: 24px;

  .mat-icon {
    font-size: 22px;
    line-height: 22px;
    width: 22px;
    height: 22px;
  }
}

.mat-button-toggle-group.small {
  .mat-button-toggle-label-content {
    line-height: 36px;
  }
}
