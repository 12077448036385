/* ----------------------------------------------------------------------------- */
/* Reset CSS and Default Definitions */
/* ----------------------------------------------------------------------------- */

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

@page {
  size: A4;
  margin: 5mm 10mm;
  size: landscape;
}

.printing-div {
  display: none;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  html,
  body {
    width: 297mm !important;
    height: 210mm !important;
    display: block;
  }

  body {
    position: relative !important;
  }

  .deep-shadow {
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;
  }

  .print-unset {
    height: auto !important;
    display: block !important;
  }

  hestia-data-table {
    position: relative;
    height: unset !important;
    display: block !important;
  }

  body,
  div {
    display: block !important;
    position: relative;
  }

  .printing-div {
    display: block;
    position: relative;
  }
  table tr {
    display: table-row-group !important;
  }
}

/* ----------------------------------------------------------------------------- */
/* Default Site settings */
/* ----------------------------------------------------------------------------- */
* {
  /* ReSharper disable once CssNotResolved */
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

a {
  text-decoration: none;
  /*font-weight: 400;*/
  -webkit-transition: border-bottom 0.35s;
  transition: border-bottom 0.35s;
  pointer-events: visiblePainted;
}

a:-webkit-any:link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.roundedToolbar {
  border-radius: 4px 4px 0 0;
}

button.menu-button {
  width: 100%;
  margin: 0;
  text-align: left;
  padding-left: 15px;
  border-left: solid 3px transparent;
}

button.menu-button.active {
  border-left: solid 3px orange;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 1000;
  background-color: black;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
}

/* ----------------------------------------------------------------------------- */
/* Sidenav und User Bar */
/* ----------------------------------------------------------------------------- */
.userBar {
  width: 100%;
  height: 50px;
  box-sizing: content-box;
  color: white;
  position: static;
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: white;
}

.userAvatar > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.userBar * > .md-button {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
}

.userBar * > md-icon {
  color: white;
}

.nav-header {
  height: 50px;
  z-index: 2;
}

.docs-logo {
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0 auto;
}

.docs-logotype {
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  margin: 0px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.docs-logo {
  border-bottom: none;
  text-decoration: none;
}

.navigationIcon > md-icon {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.no-select:focus {
  outline: none;
}

.navigationIcon:hover {
  transition: background-color 0.2s ease;
  background-color: rgba(158, 158, 158, 0.4);
  cursor: pointer;
}

.docs-menu {
  margin-top: 0px;
  margin-bottom: 0px;
}

.menu-heading {
  display: block;
  line-height: 32px;
  margin: 0;
  padding: 8px 16px 0;
  text-align: left;
  width: 100%;
  color: rgba(255, 255, 255, 0.54);
}

.docs-menu,
.docs-menu ul {
  list-style: none;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.docs-menu .md-button.active {
  background: #267ed5;
}

.docs-menu .md-button {
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: block;
  -webkit-box-align: inherit;
  -webkit-align-items: inherit;
  align-items: inherit;
  line-height: 40px;
  margin: 0;
  max-height: 40px;
  overflow: hidden;
  padding: 0px 16px;
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: 100%;
}

.toggled {
  transform: rotate(180deg);
}

/*
CH: deaktiviert weil vermutlich garnicht in Verwendung
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
} */

.docs-menu .menu-toggle-list a.md-button {
  display: block;
  padding: 0 16px 0 32px;
  text-transform: none;
  text-rendering: optimizeLegibility;
  font-weight: 500;
}

.forceSub {
  display: block;
  padding: 0 0 0 8px !important;
  text-transform: none;
  text-rendering: optimizeLegibility;
  font-weight: 200;
}

.subButton {
  display: block;
  padding: 0 16px 0 32px !important;
  text-transform: none;
  text-rendering: optimizeLegibility;
  font-weight: 500;
}

.docs-menu > li {
  border-bottom: 1px solid #267ed5;
}

.densInput {
  margin: 0px;
}

.densInput * md-input-container {
  margin: 0px;
}

.smallText {
  font-size: 0.7em;
}

.trafficLight {
  border-radius: 50%;
  height: 18px;
  width: 18px !important;
  min-width: 18px !important;
  max-width: 18px !important;
  min-height: 18px;
  margin: 3px;
  display: inline-block;
  vertical-align: bottom;
  box-shadow: 2px 2px 2px #888888;
}

.unselectedLight {
  border: 2px solid transparent;
  opacity: 0.4;
  box-shadow: 0px 0px 0px #888888;
}

.commaSpan span + span {
}

.commaSpan span + span:before {
  content: ",";
  margin-right: 5px;
}

.unselectedIcon {
  color: gray !important;
  opacity: 0.4;
}

.selectedLight {
  border: 2px solid dodgerblue;
}

.redLight {
  background-color: #d8000c;
}

.greyLight {
  background-color: rgba(0, 0, 0, 0.5);
}

.orangeLight {
  background-color: #9f6000;
}

.greenLight {
  background-color: #4f8a10;
}

.inactiveForeground {
  color: lightgray;
}

.tree-node {
  vertical-align: central;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  line-height: 24px;
  padding-left: 4px;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  font-size: 12px;
  transition: 0.5s;
}

.tree-node:hover {
  background-color: rgba(158, 158, 158, 0.2);
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  box-shadow: 2px 2px 2px rgba(164, 164, 164, 0.2);
}

.tree-node * > md-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-bottom: 4px;
}

.tree-container {
  padding: 8px;
  position: fixed;
  background-color: white;
  z-index: 4;
}

ol.tree-list {
  list-style-type: none;
  background: url(/ClientApp/src/ajs/Images/Design/vline.png) repeat-y;
  margin: 0 !important;
  margin-left: 10px !important;
  padding: 0 !important;
}

ol.tree-list ol {
  margin-left: 6px;
}

ol.tree-list li {
  margin: 0;
  padding: 0;
  padding-left: 0px;
  line-height: 24px;
  background: url(/ClientApp/src/ajs/Images/Design/node.png) no-repeat;
}

ol.tree-list li:last-child {
  background: #fff url(/ClientApp/src/ajs/Images/Design/lastnode.png) no-repeat;
}

.active-tree-node {
  background-color: rgba(180, 190, 210, 0.5);
}

.active-tree-node:hover {
  background-color: rgba(180, 190, 210, 0.7);
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 2px 2px 2px rgba(164, 164, 164, 0.2);
}

.no-error .md-errors-spacer {
  display: none;
}

.md-button.md-small {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  min-height: 24px;
  vertical-align: central;
  font-size: 12px;
  padding: 0 0;
  margin: 0;
}

.hestia-head-base {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 24px;
}

.hestia-head-sortable {
  cursor: pointer;
}

.hestia-head-resizeable {
  resize: horizontal;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 60px;
}

.hestia-cell-stretch {
  width: 100%;
}

.hestia-cell-stretch > div {
  width: 100%;
}

.hestia-cell-left {
  text-align: left !important;
  align-items: flex-start;
  align-content: flex-start;
}

.hestia-cell-center {
  text-align: center !important;
}

.hestia-cell-center > div {
  text-align: center !important;
}

.hestia-cell-right {
  text-align: right !important;
  align-items: flex-end;
  align-content: flex-end;
}

.hestia-cell-right > div {
  text-align: right !important;
  align-items: flex-end;
  align-content: flex-end;
}

.hestia-hide {
  visibility: hidden !important;
}

.hestia-link {
  color: rgb(21, 101, 192);
  text-decoration: underline;
}

.data-column-key {
  font-weight: bold;
}

.simple-data-table {
  font-size: 13px;
  width: 100%;
}

.simple-data-table > tbody > tr > td.data-column-key {
  padding-right: 5px;
}

fieldset {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  border: 0px solid gray;
}

fieldset > legend {
  background-color: white;
}

.md-no-animation {
  transition: none;
}

.md-button.md-smaller {
  height: 20px;
  line-height: 20px;
  min-height: 20px;
  vertical-align: top;
  font-size: 10px;
  padding: 0 5px;
  margin: 0 10px 10px 0;
}

md-tabs.md-no-animation md-tab-content {
  transition: none;
}

md-tabs.md-no-animation md-ink-bar {
  transition: none;
}

.hide-checkbox-content > .md-label {
  display: none;
}

.hestia-policy-container {
  margin-top: 8px;
  margin-bottom: 4px;
}

.disk-alert-override {
  position: relative;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
}

.warning-bar > * > .md-bar {
  background-color: orange !important;
}

.error-bar > * > .md-bar {
  background-color: red !important;
}

.small-list-item {
  height: 52px !important;
  min-height: 52px !important;
}

.small-list-item > .md-button.md-no-style {
  height: 52px !important;
  min-height: 52px !important;
}

.animate-repeat {
  line-height: 30px;
  list-style: none;
  box-sizing: border-box;
}

.animate-repeat.ng-move,
.animate-repeat.ng-enter .animate-repeat.ng-leave {
  transition: all linear 0.4s;
}

.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
  opacity: 0;
  max-height: 0;
}

.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px;
}

md-tab-content #md-tab1 .md-no-scroll,
#md-tab1 md-tabs,
md-tab-content.md-no-scroll {
  overflow: visible !important;
}

#md-tab2 md-tab-content,
#md-tab2 md-tabs-content-wrapper,
md-tabs.md-dynamic-height md-tabs-content-wrapper {
  z-index: 1;
}

/* Time table styles */

.vertical-line {
  border-left: 1px dashed rgb(200, 200, 200);
  position: absolute;
  margin-left: -1px;
  width: 1px;
  pointer-events: none;
}

.vertical-day-line {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  position: absolute;
  margin-left: -1px;
  width: 1px;
  pointer-events: none;
}

.current-time-line {
  border-left: 2px solid red;
  position: absolute;
  margin-left: -1px;
  width: 2px;
  z-index: 501;
  pointer-events: none;
}

.out-of-office {
  background: red;
  opacity: 0.4;
  border-radius: 5px;
}

.unkown-time {
  background: gray;
  opacity: 0.4;
  border-radius: 5px;
}

.office-time {
  background: green;
  opacity: 0.4;
  border-radius: 5px;
}

.calendar-free {
  background: white;
}

.calendar-booked {
  background-color: dodgerblue;
}

.holiday-not-approved {
  background-color: dodgerblue;
}

.holiday-approved {
  background-color: dodgerblue;
}

.holiday-unknown {
  background-color: lightgray;
}

.holiday-company-event {
  background: #99ffcc;
}

.holiday-trade-school {
  background: #ffcc99;
}

.calendar-accumulated {
  background-color: blue;
}

.calendar-holiday-entry.calendar-accumulated {
  background-color: #ff4136;
}

.calendar-holiday-entry.calendar-booked {
  background-color: #ff4136;
}

.calendar-holiday-entry.calendar-free {
  background-color: rgb(220, 220, 220);
}

.calendar-entry {
  opacity: 0.7;
  transition: opacity ease-in 0.3s;
}

.calendar-entry:hover {
  opacity: 1;
}

.calendar-private {
  background-color: rgb(255, 171, 64);
}

.calendar-oof {
  background-color: #ff4136;
}

.calendar-elswhere {
  background-color: #ffdc00;
}

.calendar-elswhere > figure > div > .calendar-entry-title,
.calendar-elswhere > figure > div > div > .calendar-entry-info {
  color: #444444;
}

.calendar-private > figure > div > .calendar-entry-title,
.calendar-private > figure > div > div > .calendar-entry-info {
  color: #444444;
}

.holiday-company-event > figure > div > .calendar-entry-title,
.holiday-company-event > figure > div > div > .calendar-entry-info {
  color: #444444;
}

.holiday-trade-school > figure > div > .calendar-entry-title,
.holiday-trade-school > figure > div > div > .calendar-entry-info {
  color: #444444;
}

.calendar-tentative {
  background-color: #aaaaaa;
}

.calendar-booked.ng-move,
.calendar-booked.ng-enter {
  transition: all ease-in 0.3s;
}

.calendar-booked.ng-leave.ng-leave-active,
.calendar-booked.ng-move,
.calendar-booked.ng-enter {
  opacity: 0;
}

.calendar-booked.ng-leave,
.calendar-booked.ng-move.ng-move-active,
.calendar-booked.ng-enter.ng-enter-active {
  opacity: 0.7;
}

.calendar-accumulated.ng-move,
.calendar-accumulated.ng-enter {
  transition: all ease-in 0.3s;
}

.calendar-accumulated.ng-leave.ng-leave-active,
.calendar-accumulated.ng-move,
.calendar-accumulated.ng-enter {
  opacity: 0;
}

.calendar-accumulated.ng-leave,
.calendar-accumulated.ng-move.ng-move-active,
.calendar-accumulated.ng-enter.ng-enter-active {
  opacity: 0.7;
}

.day-box-title {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  font-weight: bold;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.64);
}

.calendar-entry-title {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 12px;
  display: block;
  padding: 5px 2px;
}

.calendar-entry-info {
  color: lightcyan;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  font-style: italic;
  font-size: 10px;
  display: block;
  padding: 2px;
}

.tooltip-multiline {
  height: auto;
  z-index: -1 !important;
}

.interaction-container {
  cursor: grab;
}

.time-label {
  color: rgba(0, 0, 0, 0.64);
  background-color: rgb(235, 235, 235);
  font-size: 12px;
}

.timetable-group-title {
  vertical-align: middle;
  line-height: 30px;
  margin-left: 12px;
  font-weight: bold;
}

.timetable-group-header {
  height: 30px;
  max-height: 30px !important;
  min-height: 30px;
  background-color: #1565c0; /*  background-color: rgb(215, 215, 215);*/
  color: rgba(255, 255, 255, 0.84);
  z-index: 2;
}

.timetable-group-button {
  vertical-align: middle;
  line-height: 30px;
  margin-right: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.84);
}

.deep-shadow {
  -webkit-box-shadow: 3px 2px 6px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.2);
}

.no-border {
  border: 0px solid black;
}

.contact-line {
  line-height: 25px;
}

.contact-header {
  color: rgba(0, 0, 0, 0.7);
  padding-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  transition: background-color 0.2s linear;
}

.contact-class-row {
  background-color: transparent;
  transition: background-color 0.2s linear;
}

.contact-class-row:hover {
  background-color: rgb(235, 235, 235);
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
}

.contact-state-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
}

.contact-branch {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.contact-state-box {
  width: 10px;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 770px) {
  .md-panel.window-panel-container {
    position: fixed;
    display: grid;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow-x: visible;
    max-height: 100%;
    max-width: 100%;
    height: inherit;
    grid-template-rows: repeat(1, 100%);
    grid-template-columns: repeat(1, 100%);
  }
}

@media only screen and (min-width: 771px) {
  .md-panel.window-panel-container {
    position: fixed;
    transform: translate(-50%, -50%);
    display: grid;
    left: 50%;
    top: 50%;
    overflow-x: visible;
    max-height: 70%;
    max-width: 100%;
    height: inherit;
    grid-template-rows: repeat(1, 100%);
    grid-template-columns: repeat(1, 100%);
  }
}

drag-and-drop-panel {
  display: block;
  grid-row: 1;
}

drag-and-drop-panel > md-card {
  border: 1px solid rgb(200, 200, 200);
}

.window-panel-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

.contact-state-not-available {
  background-color: #ff4136;
}

.contact-state-free {
  background-color: #2ecc40;
}

.contact-state-busy {
  background-color: #ffdc00;
}

.contact-state-do-not-disturb {
  background-color: rgb(255, 171, 64);
}

.contact-state-inactive {
  background-color: #aaaaaa;
}

.button-cursor {
  cursor: pointer;
}

.button-resize-horizontal {
  cursor: col-resize;
}

.button-drag {
  cursor: grab;
}

.button-drag:active {
  cursor: grabbing;
}

.contact-filter-all {
  color: dodgerblue !important;
}

.contact-filter-available {
  color: #2ecc40 !important;
}

.animate-contact {
  list-style: none;
  box-sizing: border-box;
  transition: all linear 0.3s;
  opacity: 1;
  max-height: 55px;
}

.animate-contact.ng-hide {
  opacity: 0;
  max-height: 0;
}

.slide800 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}

.slide350 {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
}

.slide {
  will-change: transform;
}

.slide.ng-hide {
  transform: translateX(100%) scaleX(0);
}

.slide.ng-show {
  /* ReSharper disable once InvalidValue */
  transform: translateX(0) scaleX(100%);
}

.slide.ng-hide-remove,
.slide.ng-hide-add,
.slide.ng-show-remove,
.slide.ng-show-add {
  transition: all linear 500ms;
}

.fade.ng-hide {
  opacity: 0;
}

.fade.ng-hide-remove,
.fade.ng-hide-add {
}

.fade.ng-hide-remove {
  transition: all linear 1000ms;
}

.fade.ng-hide-add {
  transition: all linear 500ms;
}

.subheader-less-padding .md-subheader-inner {
  padding: 5px !important;
}

.subheader-no-padding .md-subheader-inner {
  padding: 0px !important;
}

.tree-dragging > .dragging {
  display: block;
  height: 100%;
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: unset;
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

.isDragging > * {
  pointer-events: none;
}

.dragging {
  display: block;
  height: 100%;
  width: 100%;
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(/ClientApp/src/ajs/Images/Design/drop.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  pointer-events: none;
}

.has-loading-backdrop {
  position: relative;
}

.loading-backdrop.has-loading-backdrop::before {
  opacity: 1;
}

.has-loading-backdrop::before {
  content: "";
  display: block;
  height: 100%;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.4);
  transition: opacity 0.5s linear;
}

.spinner {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(/ClientApp/src/ajs/Images/Design/circular.gif);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.fix-clear-button * button {
  margin-top: -15px !important;
}

.no-error > .md-show-clear-button > button {
  top: 3px !important;
}

.no-error > md-autocomplete-wrap > md-progress-linear {
  bottom: 0 !important;
  right: 0 !important;
  margin-bottom: -3px !important;
}

.progress-fix > md-autocomplete-wrap > md-progress-linear {
  bottom: 15px !important;
  margin-bottom: 0px !important;
}

.focused-panel {
  outline: 2px auto rgba(91, 151, 217, 0.85);
}

md-list.md-dense md-list-item.md-2-line .md-list-item-text h3,
md-list.md-dense md-list-item.md-2-line .md-list-item-text h4,
md-list.md-dense md-list-item.md-2-line .md-list-item-text p,
md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text p,
md-list.md-dense md-list-item.md-3-line .md-list-item-text h3,
md-list.md-dense md-list-item.md-3-line .md-list-item-text h4,
md-list.md-dense md-list-item.md-3-line .md-list-item-text p,
md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3,
md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h4,
md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text p {
  line-height: 1.5em !important;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 500 !important;
  /*min-width: 270px !important;*/
}

hestia-data-table {
  width: 100%;
}

.table-column-rtl {
  direction: rtl;
}

.horizontal-image {
  height: 10px;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.no-shadow > md-chips-wrap {
  box-shadow: unset !important;
  margin: 0px;
  padding-bottom: 0px;
}

.chip {
  margin-top: 0px !important;
}

.chip-selected {
  background-color: dodgerblue;
  color: white;
}

.chip-unselected {
  background-color: rgb(235, 235, 235);
  color: rgba(0, 0, 0, 0.64);
}

fieldset > legend {
  color: rgba(0, 0, 0, 0.64);
  font-weight: bold;
  font-size: 14px;
}

md-switch.no-off .md-bar {
  background-color: #333 !important; /* set not selected bar color */
}

md-switch.md-checked.no-off .md-bar {
  background-color: #333 !important; /* set selected bar color */
}

md-switch[disabled].no-off .md-bar {
  background-color: rgb(158, 158, 158) !important; /* set disabled bar color */
}

md-switch.no-off .md-thumb {
  color: rgb(158, 158, 158) !important; /* not selected switch color */
}

md-switch.no-off.md-checked .md-thumb {
  background-color: rgb(158, 158, 158) !important; /* selected switch color */
}

md-switch[disabled].no-off .md-thumb {
  background-color: rgb(158, 158, 158) !important; /* disabled switch color */
}

md-switch.no-off .md-ink-ripple {
  color: rgb(255, 0, 0) !important; /* not selected switch ripple color */
}

md-switch.no-off.md-checked .md-ink-ripple {
  color: rgb(255, 0, 0) !important; /* selected switch ripple color */
}

md-select.center-select > md-select-value {
  text-align: center;
}

md-input-container.center-label {
  margin-top: 8px;
}

md-input-container.center-label > label {
  text-align: center;
  margin-left: -18px;
  margin-top: 0px;
  transform-origin: center top !important;
  -webkit-transform-origin: center top !important;
}

md-input-container.right-label {
  margin-top: 8px;
}

md-input-container.right-label > label {
  text-align: right;
  margin-left: -18px;
  margin-top: 0px;
  transform-origin: right top !important;
  -webkit-transform-origin: right top !important;
}

.md-resize-wrapper {
  width: 100%;
}

.compare-selected {
  color: green;
  font-weight: bold;
}

.compare-dismissed {
  color: red;
}

.md-select-menu-container {
  z-index: 151 !important;
}

.md-datepicker-calendar-pane {
  z-index: 151 !important;
}

.sideMenu {
  font-size: smaller;
}

.sideMenu .md-subheader {
  background-color: dodgerblue;
}

.sideMenu .md-subheader .md-subheader-inner {
  padding: 2px 5px;
  margin: 0px;
  color: white;
  font-weight: bolder;
  width: 100%;
}

.sideMenu .md-subheader .md-subheader-inner * md-icon {
  color: white;
}

.datepickerFill {
  padding-right: 0;
  margin-right: 0;
}

.datepickerFill > .md-datepicker-input-container > .md-datepicker-input {
  max-width: unset !important;
}

.datepickerFill > .md-datepicker-input-container {
  width: 100% !important;
}

.datepickerFill > .md-datepicker-input-container > .md-datepicker-triangle-button {
  margin-right: 13px;
}

.datepickerFixedWidth > .md-datepicker-input-container {
  width: 170px !important;
}

.datepickerFixedWidth > .md-datepicker-input-container > .md-datepicker-triangle-button {
  margin-right: 13px;
}

.task-note {
  background-color: rgba(250, 250, 250, 0.6);
  border: 1px solid rgba(220, 220, 220, 0.8);
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  -webkit-box-shadow: 2px 2px 2px #888888;
  -moz-box-shadow: 2px 2px 2px #888888;
  box-shadow: 2px 2px 2px #888888;
}

.md-calendar-day-header {
  background: rgba(158, 158, 158, 0.92) !important;
}

.date-picker {
  display: inline-block;
  padding: 0;
  width: 100% !important;
}

.date-picker > .md-datepicker-input-container {
  display: flex;
}

.date-picker > md-datepicker-button {
  margin-left: -12px;
}

.date-picker > .md-datepicker-input-container > .md-datepicker-input {
  max-width: 100%;
  min-width: 100%;
}

.date-picker > .md-datepicker-input-container > .md-datepicker-triangle-button {
  right: 4px;
}

.smallerButton * .md-button {
  height: 40px !important;
}

.smallerButton * .md-no-style {
  height: 40px !important;
}

.smallIcon * .md-button {
  width: 24px !important;
  height: 24px !important;
  min-height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
}

md-pagination-wrapper {
  width: auto !important;
}

.fixTabHeader * md-tab-item {
  min-width: 250px !important;
}

.fix-contact-chip * input {
  min-width: 580px;
}

.smaller-contact-chips * md-chips-wrap {
  margin: 0px;
  padding: 0px;
}

.smaller-contact-chips * md-autocomplete {
  margin-top: -12px;
  margin-bottom: 2px;
}

.smaller-contact-chips * .md-contact-name {
  font-size: 12px !important;
  line-height: 24px;
  height: 24px !important;
}

.md-contact-suggestion {
  display: block;
  min-width: 250px;
}

.md-contact-suggestion .md-contact-name {
  display: inline-block;
  width: 200px;
  margin-left: 6px;
  line-height: 32px;
  float: left;
}

.md-contact-suggestion .md-contact-email {
  display: inline-block;
  line-height: 14px;
  margin-left: 6px;
  font-size: 12px;
  width: 200px;
  float: left;
}

.md-contact-suggestion img {
  float: left;
  margin-left: -10px;
}

.smaller-contact-chips * md-chip {
  height: 24px !important;
  line-height: 24px !important;
  border-radius: 12px !important;
}

.smaller-contact-chips * img {
  height: 24px !important;
  border-radius: 12px !important;
}

.smaller-contact-chips * .md-chip-remove {
  height: 24px !important;
  width: 24px !important;
}

.smaller-contact-chips * md-icon {
  height: 14px !important;
  width: 14px !important;
  min-height: 14px !important;
  min-width: 14px !important;
}

.md-chips {
  padding: 8px 0 0 3px;
}

.md-chips .md-chip-input-container {
  padding: 0;
}

.md-chips .md-chip-input-container md-autocomplete {
  padding: 4px 0 0 0 !important;
}

.md-chips .md-chip-input-container md-autocomplete input {
  padding-top: 8px;
  font-size: 14px;
}

.md-chips .md-chip div.md-chip-content {
  font-size: 12px;
}

.md-chips * .md-contact-name {
  font-size: 14px;
}

.readonly * md-chips-wrap {
  box-shadow: none;
  cursor: default;
}

.readonly * md-chip {
  padding-right: 5px !important;
}

.readonly * .md-chip-remove {
  display: none;
}

.readonly * .md-chip-input-container md-autocomplete {
  display: none;
  cursor: default;
}

.screen-preview {
  cursor: default;
  width: 1920px;
  height: 1080px;
  border: 0px none transparent;
  transform: scale(0.1);
  transform-origin: 0 0;
  margin: 0 0 -972px 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 3px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu .title {
  font-weight: bold;
  margin: auto;
  padding: 3px 20px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.print-focus {
  background-color: white;
  height: auto;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
}

.hestia-print-table thead tr th {
  border-bottom: 1px solid black;
  border-collapse: separate;
  border-spacing: 5px 5px;
}

.unset-padding {
  padding: 0 !important;
}

.smallerQr > canvas {
  max-width: 100% !important;
}

.smaller-option {
  padding: 0px 8px;
  margin: 0;
  height: 24px;
  line-height: 24px;
  max-height: 24px;
}

.label-preview {
  margin: 10px auto;
  border: solid 1px black;
  border-radius: 5px;
  overflow: hidden;
}

.label-line {
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Verdana, Geneva, sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.smallChips {
  padding: 0;
}

.smallChips .md-chip-input-container md-autocomplete input {
  padding-top: 0;
}

.smallChips md-chips-wrap {
  padding: 0;
  padding-bottom: 3px;
}

.smallChips * {
  font-size: 12px;
}

.smallChips * svg {
  height: 18px;
  width: 18px;
  margin: 3px;
}

md-toast {
  margin-bottom: 50px;
  margin-right: 5px;
}

.highlight-element {
  background-color: transparent;
  transition: background-color 0.2s linear;
}

.highlight-element:hover {
  background-color: rgb(235, 235, 235);
}

.highlight-selected {
  background-color: dodgerblue;
  transition: background-color 0.2s linear;
  color: white !important;
}

.highlight-selected:hover {
  background-color: rgb(188, 188, 188);
  color: white;
}

.hestia-table-heading {
  position: sticky;
  top: 0;
  z-index: 1;
}

.hestia-table-heading-column {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 6px;
  font-size: 12px;
  font-weight: 600;
  color: #757575;
  height: 26px;
}

.hestia-table-body > div > div {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 6px;
  line-height: 24px;
}

.hestia-table-heading-checkbox {
  padding-left: 12px;
  padding-right: 0px;
}

.hestia-table-heading-checkbox > md-checkbox {
  margin-top: 2px;
  padding: 0px;
}

.hestia-table-body > div {
  border-bottom: solid 1px lightgray;
  transition: background-color 0.2s linear;
}

.hestia-table-body > div:hover {
  background-color: #eeeeee;
}

.hestia-table-body-select {
  margin: 0px;
  margin-top: 2px;
  margin-bottom: -6px;
  padding: 0px;
}

.hestia-table-footer-actions > div {
  border-top: solid 1px lightgray;
}

.hestia-table-footer-actions > div > div {
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 6px;
  line-height: 24px;
}

.hestia-row-aggregate {
  border-top: solid 1px lightgray;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
}

#view-container {
  overflow: auto;
  display: grid;
  width: 100%;
  position: relative;
  height: 100%;
  min-width: 100%;
  align-items: stretch;
  grid-template-columns: repeat(1, 100%);
}

.view-animate {
  display: flex;
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
}

.view-animate.ng-enter,
.view-animate.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.view-animate.ng-enter {
  opacity: 0;
  z-index: 1;
}

.view-animate.ng-leave {
  opacity: 1;
  z-index: 0;
}

.view-animate.ng-enter-active {
  opacity: 1;
}

.view-animate.ng-leave-active {
  opacity: 0;
}

.app-loading-bar {
  position: absolute;
  z-index: 90000;
  will-change: auto;
}

.app-loading-bar > .md-container {
  background-color: rgba(255, 0, 0, 0.3);
}

.app-loading-bar > .md-container > .md-bar {
  background-color: rgba(255, 0, 0, 0.7);
}

.side-navigation-bar {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
  position: relative;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.side-navigation-bar-inner:hover {
  width: 272px;
  transition: width ease-out 0.1s;
  -webkit-transition: width ease-out 0.1s;
}

.side-navigation-bar-inner:hover .widget-content {
  display: flex;
  opacity: 1;
  transition: opacity ease-out 0.2s;
  -webkit-transition: opacity ease-out 0.2s;
}

.widget-content {
  position: fixed;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity ease-out 0s;
  -webkit-transition: opacity ease-out 0s;
}

.side-navigation-bar-inner:hover .widget-button {
  position: fixed;
  bottom: 0;
  opacity: 0;
  transition: opacity ease-out 0s;
  -webkit-transition: opacity ease-out 0s;
}

.widget-button {
  display: flex;
  opacity: 1;
  transition: opacity ease-out 0.2s;
  -webkit-transition: opacity ease-out 0.2s;
}

.side-navigation-navigating {
  width: 60px !important;
}

.side-navigation-bar-inner {
  position: fixed !important;
  width: 60px;
  max-width: 272px;
  min-width: 60px;
  overflow: hidden;
  -webkit-transition: width ease-in 0.1s;
  transition: width ease-in 0.1s;
}

.navigationIcon {
  padding: 0px;
  height: 40px;
}

.navigationIcon > span {
  margin: auto 5px;
  color: white;
  font-weight: 400;
}

.active-navigation-link {
  background-color: #01579b !important;
}

.side-navigation-icon {
  margin: auto;
  margin-right: 8px;
  margin-left: 8px;
  width: 44px;
  text-align: center;
  color: white !important;
}

.nav-header > a {
  margin-top: 8px;
}

.nav-header > a > img {
  width: 35px;
  height: 35px;
  margin: auto 12.5px;
}

.site-sidenav > * {
  min-width: 218px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
}

.site-sidenav {
  background: #106cc8;
  height: 100%;
}

.site-sidenav,
.site-sidenav.md-locked-open-add-active,
.site-sidenav.md-locked-open {
  /*width: 272px;
    min-width: 272px;
    max-width: 272px;*/
}

.hide-whiteframe-border > md-autocomplete-wrap {
  box-shadow: none;
}

/* --- UserBar --- */
app-mandator-selector.isMobile {
  /* margin nötig, damit mobil das md-menu nicht verdeckt ist. */
   margin-left: 50px;
}

/*
    commit be0f0b7d "Fix datepicker clear buttons"
    hierher verschoben wegen Umstellung BundleConfig->webpack
*/
.hestia-no-clear .dtp-clear {
  visibility: collapse !important;
}

.systembuilder-icon-container{
  min-width: 40px;
  display: flex;
}

/* Für Dialoge in dialogConfig.panelClass um das Padding zu entfernen*/
.full-dialog .mat-dialog-container {
  padding: 0;
}
