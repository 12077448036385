// Platzsparendere Chips; überschreiben den Standard-Style
.mat-chip-list.dense .mat-chip {
  min-height: 24px !important;
  padding: 6px 10px !important;

  .mat-chip-trailing-icon {
    margin-left: 4px;
  }
}

// Für Situationen wo wir keine mat-chip-list verwenden wollen (z.B. Autocompletes)
// gibt es einen div-Style, der den mat-chip-Style nachahmt.
// Kann auch mit .dense kombiniert werden.
div.mat-chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  &.dense {
    gap: 4px;
  }

  div.mat-chip {
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    min-height: 32px;
    height: 1px;
    box-sizing: border-box;
    white-space: nowrap;

    a {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }
}
