/* 
Das Repo/Package von Google wird nicht mehr gepflegt, deshalb verwenden
wir folgenden Fork: https://github.com/jossef/material-design-icons-iconfont

Verfügbare Icons: https://jossef.github.io/material-design-icons-iconfont/
*/

$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

@import "~material-design-icons-iconfont/src/material-design-icons";
