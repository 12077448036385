// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ClientApp-primary: mat.define-palette(mat.$light-blue-palette, 700);
$ClientApp-accent: mat.define-palette(mat.$orange-palette);

// The warn palette is optional (defaults to red).
$ClientApp-warn: mat.define-palette(mat.$red-palette);

// Inter als Standard-Font laut Design von petbas.com
$ClientApp-typography: mat.define-typography-config(
  $font-family: "Inter",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ClientApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $ClientApp-primary,
      accent: $ClientApp-accent,
      warn: $ClientApp-warn,
    ),
    typography: $ClientApp-typography,
  )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($ClientApp-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ClientApp-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Workaround für AngularJS:
// Nur Element unter .mat-typography bekommen die Inter-Schriftart,
// damit AngularJS-Komponenten weiterhin Roboto verwenden.
.mat-typography {
  font-family: Inter, Roboto, "Helvetica Neue", sans-serif !important;

  a {
    color: mat.get-color-from-palette($ClientApp-primary, 700);

    // wenn <a> nicht farbig angezeigt werden soll
    &.inherit-color {
      color: inherit;
    }
  }
}

// Workaround:
// Standard color ist rgba(0,0,0,.87), aber Schrift wird in Chrome wesentlich heller
// angezeigt, daher black als neuen Standard.
md-content.md-cpdefault-theme {
  color: black;
  background-color: white;
}
