// Bugfix für ngx-mat-calendar
// https://github.com/h2qutc/angular-material-components/issues/256
ngx-mat-calendar {
  .mat-calendar-body-cell {
    position: relative !important;
  }
}

mat-calendar {
  .mat-calendar-arrow {
    width: 0;
    height: 0;
  }

  .mat-calendar-body-cell {
    position: absolute !important;
    height: 100%;
  }
}

ngx-mat-datetime-content {
  .mat-calendar-arrow {
    width: 0;
  }
}
