@use "./colors" as colors;

quill-editor {
  // Standard ist inline, aber dann wird der Editor+Toolbar größer als der Parent Container
  display: block !important;

  // Wenn eine fixe Höhe gesetzt werden soll, sollte das auf div.ql-editor gemacht werden.
}

// Design an Material angleichen
quill-editor.material {
  &[customToolbarPosition="top"],
  &:not([customToolbarPosition]) {
    div.ql-container {
      border-radius: 0 0 5px 5px;
      border: 1px solid colors.$material-border-color !important;
      border-top: 0 !important;
    }

    div.ql-toolbar {
      border-radius: 5px 5px 0 0;
      border: 1px solid colors.$material-border-color !important;
      border-bottom: 0 !important;
    }
  }

  &[customToolbarPosition="bottom"] {
    div.ql-container {
      border-radius: 5px 5px 0 0;
      border: 1px solid colors.$material-border-color !important;
      border-bottom: 0 !important;
    }

    div.ql-toolbar {
      border-radius: 0 0 5px 5px;
      border: 1px solid colors.$material-border-color !important;
      border-top: 0 !important;
    }
  }

  div.ql-toolbar {
    // Wenn ein quill-editor innerhalb einer table verwendet wird, erbt die toolbar
    // ein border-spacing von der table und wird höher. Das wird hiermit vermieden.
    border-spacing: 0;
  }
}
