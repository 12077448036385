// mat-inputs haben normalerweise einen margin nach unten, in dem der mat-hint
// eingeblendet werden kann. In manchen Situationen sieht das komisch aus, z.B. wenn man
// einen Rahmen um ein mat-input machen möchte.
//
// Mit <mat-form-field class="remove-hint"> kann man diesen margin entfernen. Es darf dann
// natürlich kein <mat-hint> mehr verwendet werden.
//
// https://newbedev.com/how-to-remove-space-bottom-mat-form-field
mat-form-field.remove-hint .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

// Bei mat-inputs mit einem mat-icon als Prefix ist das mat-icon nicht auf Höhe
// mit dem Text, und das Icon ist sehr glatt am input dran.
// Bei fill und outline tritt das Problem nicht auf.
mat-form-field:not([appearance="fill"]):not([appearance="outline"]) {
  .mat-form-field-prefix > mat-icon {
    margin-right: 4px;
    transform: translateY(4px);
  }
}

// Der Hintergrund von mat-form-fields ist normalerweise transparent.
// Wenn wir auf einem andersfarbigen Hintergrund ein weißes Eingabefeld haben wollen,
// kann man diesen Style verwenden.
// <mat-form-field appearance="outline" class="white-background">
.mat-form-field-appearance-outline.white-background .mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px;
}

// Damit das form-field kleiner als 180px sein kann
.mat-form-field.no-min-width .mat-form-field-infix {
  width: auto !important;
}
