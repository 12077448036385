// mat-icons, die als Avatar in mat-card-headers verwendet werden
mat-icon.mat-card-avatar {
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: gray;
}

// scrollable mat-card:
//
// Bewirkt, dass in der mat-card eine Scrollbar angezeigt wird, wenn der mat-card-content zu groß
// ist. mat-card-header, mat-card-actions, mat-card-footer bleiben sichtbar.
// Es wurden auch paddings/magins angepasst, damit die Scrollbar ganz am Rand der mat-card angzeigt
// wird. Wenn man trotzdem ein padding haben möchte, muss man sich selbst drum kümmern
//  (div mit margin:16px im mat-card-content).
.mat-card.scrollable {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  box-sizing: border-box;

  // padding von 16px entfernen, damit der scrollbare content bis an den Rand gehen kann.
  padding: 0;

  > .mat-card-header {
    margin: 16px 16px 0 16px;
  }

  > .mat-card-content {
    flex: 1 1 100%;
    overflow: auto;
    margin-bottom: 8px;

    // flex bewirkt hier folgendes:
    // Wenn im mat-card-content mehrere Elemente sind, und eines davon overflow:auto hat, scrollt
    // nicht der gesamte mat-card-content, sondern nur dieses Element.
    //
    // Beispiel:
    // <mat-card-content>
    //   <div style="overflow:auto">
    //     <table>...</table>
    //   </div>
    //   <mat-paginator></mat-paginator>
    // </mat-card-content>
    //
    // Wenn in der table mehr Zeilen sind als angezeigt werden können, beginnt die table zu scrollen,
    // aber der mat-paginator bleibt immer sichtbar.
    display: flex;
    flex-direction: column;
  }

  > .mat-card-actions {
    margin: 8px !important;
    padding: 0;
  }

  > .mat-card-footer {
    margin: 0;
  }
}
